import * as THREE from "three";

import {Scene} from "three";
import Score from "../logic/Score";
import GameObject from "../logic/GameObject";
import Floor from "./Floor";
import Game from "../Game";
import {SimpleEventDispatcher} from "strongly-typed-events";
import {ISimpleEvent} from "ste-simple-events/dist/definitions";
import End from "../../views/End";
import {hideElement, showElement, showFlexElement} from "Utils";
import AudioManager from "./AudioManager";
import ScoreChangedEventArgs from "../events/ScoreChangedEventArgs";
import Ammo from "ammojs-typed";
import Config = Ammo.Config;
import {GameConfig} from "../GameConfig";
import Rose from "./Rose";

export enum ScreenSizeType {
    small,
    medium,
    large
}

export default class GameView extends GameObject {

    private mobile = 768;
    private tablet = 1200;
    private currentScreenSizeType: ScreenSizeType;
    private onScreenSizeTypeChanged = new SimpleEventDispatcher<ScreenSizeType>();

    private readonly renderer: THREE.WebGLRenderer;
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private textureLoader: THREE.TextureLoader;
    private score: Score;
    private areResultsDisplayed: boolean;
    private audioManager: AudioManager;

    private audioSource: THREE.Audio;
    private rose: Rose;

    private readonly refResolution = {
        x: 1920,
        y: 1080
    };

    /**
     * Ctor.
     * @param score
     * @param audioManager
     */
    constructor(score: Score, audioManager: AudioManager) {
        super();
        this.score = score;
        this.audioManager = audioManager;
        this.rose = new Rose();
        //this.audioSource = new THREE.Audio(this.audioManager.AudioListener);

        const canvasBox = <HTMLCanvasElement>document.getElementById("webgl-canvas");
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvasBox,
            alpha: true,
        });
        this.scene = new THREE.Scene();
        this.textureLoader = new THREE.TextureLoader();
        this.setupCamera();

        this.score.scoreChanged.subscribe((scoreChangedEventArgs: ScoreChangedEventArgs) => {
            this.onScoreChangedHandler(scoreChangedEventArgs);
        });

        this.score.comboChanged.subscribe((combo: number) => {
            this.onComboChangedHandler(combo);
        });
    }

    /**
     * Sets camera
     * @private
     */
    private setupCamera() {
        this.camera = new THREE.PerspectiveCamera(50, 1, 0.1, 20);
        //this.camera.add(audioListener);
        const pos = new THREE.Vector3(0, 2.2, 1.1);
        this.camera.position.copy(pos);
        this.camera.rotation.x = -(20 * Math.PI / 180);
    }

    resizeRendererToDisplaySize(renderer: THREE.Renderer): boolean {
        const canvas = renderer.domElement;
        const pixelRatio = window.devicePixelRatio;
        const width = canvas.clientWidth * pixelRatio | 0;
        const height = canvas.clientHeight * pixelRatio | 0;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    }

    /**
     * Update.
     * @param deltaTime
     * @param secs
     */
    update(deltaTime: number, secs: number): void {
        if (this.resizeRendererToDisplaySize(this.renderer)) {
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
            this.checkBreakPoints(canvas.clientWidth);
            this.displayCoverElements(canvas.clientWidth, canvas.clientHeight);
        }

        this.renderer.render(this.scene, this.camera);
    }

    /**
     * Setups the scene elements
     */
    setupScene(): void {
        new Floor();
    }

    displayCoverElements(width: number, height: number) : void{

        let backgroundWidth = width;
        let backgroundHeight = height;

        if ( (height * 16 / 9) > width ) {
            backgroundWidth = height * 16 / 9;
        } else {
            backgroundHeight = 9 / 16 * width;
        }

        const scorePanel = document.getElementById("score-desktop-panel");
        const rose = document.getElementById("animation-rose");
        const ship = document.getElementById("animation-ship");
        if(width >= this.tablet){
            // display score desktop panel
            this.displayCoverElement(scorePanel, 1450, 200, 261, 800, width, height, backgroundWidth, backgroundHeight);

        } else {
            scorePanel.removeAttribute("style");
        }

        this.displayCoverElement(rose, 500, 560, 1298/3, 662/1.5, width, height, backgroundWidth, backgroundHeight);
        this.displayCoverElement(ship, -200, 100, 905/3, 60, width, height, backgroundWidth, backgroundHeight);

    }

    displayCoverElement(elem : HTMLElement, leftPos:number, topPos:number, elemWidth: number, elemHeight: number, canvasWidth:number, canvasHeight:number, backgroundWidth: number, backgroundHeight: number ) : void {
        const x = leftPos * backgroundWidth / this.refResolution.x;
        const y = topPos * backgroundHeight / this.refResolution.y;

        const scaleX = backgroundWidth / this.refResolution.x;
        const scaleY = backgroundHeight / this.refResolution.y;

        const xOffset = (backgroundWidth - canvasWidth) / 2;
        const yOffset = (backgroundHeight - canvasHeight) / 2;

        elem.style.width = (elemWidth * scaleX) + "px";
        elem.style.height = (elemHeight * scaleY) + "px";
        elem.style.left = ( x - xOffset) + "px";
        elem.style.top = ( y - yOffset) + "px";
    }

    /**
     *
     * @param duration
     */
    changeGameDuration(duration: number): void {
        const date = new Date(null);
        date.setSeconds(duration);
        const result = date.toISOString().substr(14, 5);
        document.getElementById("duration-amount").innerHTML = String(result);
    }

    /**
     * Show success view
     */
    private showSuccess(scoreAmount: number): void {
        //this.audioManager.playSound("correct.mp3", 0.3, this.audioSource);
        this.showThrowState("success", scoreAmount);
        this.rose.happy();
    }

    /**
     * Show fail view
     */
    private showFail(): void {
        this.showThrowState("fail", Game.config.scorePerMistake);
        //this.audioManager.playSound("incorrect.mp3", 0.3, this.audioSource);
        this.rose.sad();
    }

    /**
     * Show miss
     */
    private showMiss(): void {
        this.showThrowState("miss", Game.config.scorePerMiss);
        //this.audioManager.playSound("incorrect.mp3", 0.3, this.audioSource);
        this.rose.sad();
    }

    /**
     * Show joker phase
     */
    showJokerPhasePopup(numberOfDetritus: number): void {

        const elem = document.getElementById("joker-phase-msg");
        showFlexElement(elem);
        const phase1 = document.getElementById("joker-phase-1");
        const phase2 = document.getElementById("joker-phase-2");
        const phase3 = document.getElementById("joker-phase-3");
        const phase4 = document.getElementById("joker-phase-4");
        const amount = document.getElementById("joker-phase-amount");
        amount.innerHTML = numberOfDetritus.toString();
        showElement(phase1);

        setTimeout(() => {
            hideElement(phase1);
            showElement(phase2);
        }, 4000);

        setTimeout(() => {
            hideElement(phase2);
            showElement(phase3);
        }, 5500);

        setTimeout(() => {
            hideElement(phase3);
            showElement(phase4);
        }, 6500);

        setTimeout(() => {
            hideElement(elem);
        }, 7500);

    }

    /**
     * Show result
     */
    showResult(config: GameConfig, playedTime: number): void {
        this.areResultsDisplayed = true;
        // TODO: Hmm plutôt le biz de "game"
        const end = new End(this.score.Score, this.score.ScoresHistory, playedTime, config);
    }

    /**
     * Hide startup text
     */
    hideStartupText(): void {
        const elem = document.getElementById("startup");
        elem.classList.remove("is-visible-flex");
    }

    showJokerPhase(shots: number): void {
        document.getElementById("score-desktop-panel").classList.add("in-joker-phase");
        const amount = document.getElementById("joker-shots-remaing");
        amount.innerHTML = shots.toString();
    }

    /**
     * Show HTML components
     * @param elemId
     * @param score
     */
    showThrowState(elemId: string, score: number): void {
        if (this.areResultsDisplayed) {
            return;
        }

        const elem = document.getElementById(elemId);
        elem.getElementsByTagName("span")[0].innerHTML = String(Math.abs(score));
        showElement(elem);
        window.setTimeout(() => {
           elem.classList.remove("is-visible");
        }, 800);
    }

    /**
     * Handles a score change
     * @param scoreChangedEventArgs
     */
    onScoreChangedHandler(scoreChangedEventArgs: ScoreChangedEventArgs): void {
        document.getElementById("score-amount").innerHTML = String(scoreChangedEventArgs.Score);
        if(scoreChangedEventArgs.ScoreAmount > 0){
            this.showSuccess(scoreChangedEventArgs.ScoreAmount);
        } else if(scoreChangedEventArgs.HasMiss){
            this.showMiss();
        } else {
            this.showFail();
        }
    }

    private onComboChangedHandler(combo: number) {
        const elem = document.getElementById("combo");
        if(combo > 0){
            showElement(elem);
            document.getElementById("combo-amount").innerHTML = String(combo);
        } else {
            hideElement(elem);
        }
    }

    /**
     *
     * @param width
     * @private
     */
    private checkBreakPoints(width: number): void {
        if (width <= this.mobile) {
            this.setScreenSizeType(ScreenSizeType.small);
        } else if (width > this.mobile && width <= this.tablet) {
            this.setScreenSizeType(ScreenSizeType.medium);
        } else if (width > this.tablet) {
            this.setScreenSizeType(ScreenSizeType.large);
        }
    }

    private setScreenSizeType(type: ScreenSizeType) {
        if (this.currentScreenSizeType != type) {
            this.currentScreenSizeType = type;
            this.onScreenSizeTypeChanged.dispatch(type);
        }
    }

    /**
     * Returns the main scene
     */
    get mainScene(): Scene {
        return this.scene;
    }

    /**
     * Returns main camera
     */
    get mainCamera(): THREE.Camera {
        return this.camera;
    }

    get getCurrentScreenSizeType(): ScreenSizeType {
        return this.currentScreenSizeType;
    }

    /**
     * Screen size type event
     */
    get screenSizeTypeChanged(): ISimpleEvent<number> {
        return this.onScreenSizeTypeChanged.asEvent();
    }
}