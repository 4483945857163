import * as THREE from "three";

export interface AudioSources {
    [details: string] : THREE.Audio;
}

export default class AudioManager {

    private listener: THREE.AudioListener;
    private SOUND_PATH = "sounds/";

    private musicAudioSource: THREE.Audio;
    private audioLoader: THREE.AudioLoader;
    private camera:THREE.Camera;
    private audioSources: AudioSources;

    private readonly MAIN_MUSIC = "music-1.mp3";
    private readonly JOKER_MUSIC = "music-joker.mp3";


    /**
     * Ctor.
     */
    constructor(camera: THREE.Camera) {
        this.camera = camera;
        this.audioSources = {};

        //this.audioLoader = new THREE.AudioLoader();
        //this.listener = new THREE.AudioListener();
        //this.musicAudioSource = new THREE.Audio(this.listener);

        // window.addEventListener("touchstart", ()=>{
        //     this.init();
        // });
        // document.addEventListener("click", ()=>{
        //     this.init();
        // });
    }
    
    init(): void {
        if(this.listener != null) {
            return;
        }
        this.audioLoader = new THREE.AudioLoader();
        this.listener = new THREE.AudioListener();
        this.camera.add( this.listener );
        this.playMainMusic();
    }


    playMainMusic(): void {
        this.playSound(this.MAIN_MUSIC, 0.2, true);
    }

    playJokerMusic(): void {
        if(this.audioSources[this.MAIN_MUSIC] != null){
            this.audioSources[this.MAIN_MUSIC].stop();
        }
        this.playSound(this.JOKER_MUSIC, 0.2, true);
    }

    playSound(soundName: string, volume: number, loop = false): void {

        if(this.listener == null) return;

        let audioSource:THREE.Audio = null;
        if(this.audioSources[soundName] == null){

            audioSource = new THREE.Audio(this.listener);
            this.audioSources[soundName] = audioSource;
        } else {
            audioSource = this.audioSources[soundName];
        }
        if(audioSource.isPlaying){
            audioSource.stop();
        }
        this.audioLoader.load(this.SOUND_PATH + soundName,  (buffer) => {
            audioSource.setBuffer(buffer);
            audioSource.setLoop(loop);
            audioSource.setVolume(volume);
            audioSource.play();
        });
    }

    pauseMusic() : void {
        this.musicAudioSource.pause();
    }

    stopMusic(): void {
        if(this.audioSources[this.MAIN_MUSIC] != null){
            this.stopSound(this.audioSources[this.MAIN_MUSIC]);
        }
        if(this.audioSources[this.JOKER_MUSIC] != null){
            this.stopSound(this.audioSources[this.JOKER_MUSIC]);
        }
    }

    stopSound(audioSource: THREE.Audio): void {
        if(audioSource.isPlaying){
            audioSource.stop();
        }
    }

    get AudioListener(): THREE.AudioListener {
        return this.listener;
    }
}