import * as THREE from "three";

export default class TrajectoryCollisionPoint {

    private mesh:THREE.Mesh;

    constructor(mainScene: THREE.Scene) {
        const geometry = new THREE.CircleGeometry(0.005, 30);
        const material = new THREE.MeshBasicMaterial({color: 0xD9FFB9, transparent: true, opacity: 0});
        this.mesh = new THREE.Mesh(geometry, material);
        mainScene.add(this.mesh);
    }

    update(deltaTime: number, secs: number) : void {

    }

    setPosition(position: THREE.Vector3) : void {
        this.mesh.position.copy(position);
    }

    getPosition() : THREE.Vector3 {
        return  this.mesh.position;
    }
}