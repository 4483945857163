import * as THREE from "three";
import {MathUtils, Vector3} from "three";
import Game from "../Game";
import Ammo from "ammojs-typed";
import GamePhysics from "./GamePhysics";
import Detritus from "./Detritus";
import {SimpleEventDispatcher} from "strongly-typed-events";
import {ISimpleEvent} from "ste-simple-events/dist/definitions";
import {DetritusType} from "./DetritusType";
import {clamp} from "../../utils";
import TrajectoryPoint from "./TrajectoryPoint";
import {GameState} from "./GameState";
import TrajectoryCollisionPoint from "./TrajectoryCollisionPoint";
import Dustbin from "./Dustbin";
import DustbinsManager from "./DustbinsManager";
import AudioManager from "../view/AudioManager";
import {ITexture} from "./ITexture";

export default class DetritusLauncher {

    // Events
    private onDetritusLaunched = new SimpleEventDispatcher<Detritus>();

    private mainScene: THREE.Scene;
    private detrituses: Detritus[];
    private currentLaunchedDetritus: Detritus;
    private audioManager: AudioManager;

    // Swipe variables
    private isSwiping: boolean;
    private swipeTimer: number;
    private swipeFrom: THREE.Vector2;

    // Throw variables
    private aimingVector: THREE.Vector3;
    private trajectoryPoints: TrajectoryPoint[];
    private trajectoryCollisionPoints: TrajectoryCollisionPoint[];
    private showTrajectory: boolean;
    private trajectoryNumberOfPoints = 10;
    private trajectoryCollisionNumberOfPoints = 200;
    private isLaunchAllowed: boolean;
    private defaultDetritusPosition = new THREE.Vector3(0, 1.3, 0);
    private launchPosition = new THREE.Vector3().copy(this.defaultDetritusPosition);
    private dustbins: Dustbin[];
    private textures: ITexture;

    // sounds
    private readonly THROW_SOUND = "launch.mp3";
    private throwAudioSource: THREE.Audio;

    /**
     * Ctor.
     * @param mainScene
     * @param game
     * @param camera
     * @param dustbinsManager
     * @param audioManager
     * @param textures
     */
    constructor(mainScene: THREE.Scene, game: Game, camera: THREE.Camera, dustbinsManager: DustbinsManager, audioManager: AudioManager, textures: ITexture) {
        this.detrituses = [];
        this.dustbins = dustbinsManager.getDustbins();
        this.mainScene = mainScene;
        this.audioManager = audioManager;
        this.isLaunchAllowed = true;
        this.textures = textures;

        game.stateChanged.subscribe((state: GameState) => {
            this.onGameStateChanged(state);
        });

        dustbinsManager.layoutChanged.subscribe((dustbins: Dustbin[]) => {
            this.onDustbinsLayoutChanged(dustbins);
        });

        if (window.PointerEvent) {
            window.addEventListener("pointerdown", (event) => {
                this.swipeStartHandler(this.pointerEventToScreenVector2(event));
            });

            window.addEventListener("pointermove", (event) => {
                this.swipingHandler(this.pointerEventToScreenVector2(event));
            });

            window.addEventListener("pointerup", (event) => {
                this.swipeEndHanlder(this.pointerEventToScreenVector2(event));
            });
        }

        window.addEventListener("touchstart", (e) => {
            if(this.isLaunchAllowed){
                e.preventDefault();
            }
        },{ passive: false });

        this.createTrajectoryPoints();
    }

    update(deltaTime: number, secs: number): void {

        for (let i = 0; i < this.detrituses.length; i++) {
            this.detrituses[i].update(deltaTime, secs);
        }

        for (let i = 0; i < this.trajectoryPoints.length; i++) {
            this.trajectoryPoints[i].update(deltaTime, secs);
        }

        for (let i = 0; i < this.trajectoryCollisionPoints.length; i++) {
            this.trajectoryCollisionPoints[i].update(deltaTime, secs);
        }

        if (this.isSwiping) {
            this.swipeTimer += deltaTime;
        }

        this.drawTrajectory();
    }

    private createTrajectoryPoints(): void {
        this.trajectoryPoints = [];
        for (let i = 0; i < this.trajectoryNumberOfPoints; i++) {
            const point = new TrajectoryPoint(this.mainScene);
            this.trajectoryPoints.push(point);
        }

        this.trajectoryCollisionPoints = [];
        for (let i = 0; i < this.trajectoryCollisionNumberOfPoints; i++) {
            const point = new TrajectoryCollisionPoint(this.mainScene);
            this.trajectoryCollisionPoints.push(point);
        }
    }

    private swipeStartHandler(position: THREE.Vector2) {
        if (this.currentLaunchedDetritus == null || this.isSwiping) {
            return;
        }
        document.body.classList.add("no-cursor");
        this.swipeFrom = position;
        this.swipeTimer = 0;
        this.isSwiping = true;
        this.showTrajectory = true;
    }

    private swipingHandler(position: THREE.Vector2) {
        if (this.isSwiping && this.currentLaunchedDetritus != null) {
            const direction: THREE.Vector3 = this.getVelocityVector(position);
            const clampedForce = clamp(position.distanceTo(this.swipeFrom) * Game.config.throwForce, Game.config.throwMinForce, Game.config.throwMaxForce);
            direction.multiplyScalar(clampedForce);
            this.aimingVector = direction;
            this.displayTrajectoryPoints(clampedForce);
        }
    }

    private swipeEndHanlder(position: THREE.Vector2) {
        if (!this.isSwiping) {
            return;
        }
        document.body.classList.remove("no-cursor");
        this.isSwiping = false;
        this.prepareForLaunch(position);
    }

    private pointerEventToScreenVector2(event: PointerEvent): THREE.Vector2 {
        const vector2 = new THREE.Vector2();
        vector2.set(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 3 + 1
        );
        return vector2;
    }

    createDetritus(detritusType: DetritusType): void {
        if (!this.isLaunchAllowed) {
            return;
        }
        if (this.currentLaunchedDetritus != null) {
            this.currentLaunchedDetritus.destroy(true);
        }

        const detritus = new Detritus(this.mainScene, detritusType, this.defaultDetritusPosition, this.audioManager, this.textures);
        detritus.rigidbody.setGravity(new Ammo.btVector3(0, 0, 0));
        this.detrituses.push(detritus);
        this.currentLaunchedDetritus = detritus;
    }

    private prepareForLaunch(position: THREE.Vector2) {
        const v = position.distanceTo(this.swipeFrom);
        if (v == 0) {
            return;
        }
        // TODO: Remove this shit
        if(v > 0.6){
            const direction: THREE.Vector3 = this.getVelocityVector(position);
            const clampedForce = clamp(v * Game.config.throwForce, Game.config.throwMinForce, Game.config.throwMaxForce);
            direction.multiplyScalar(clampedForce);
            this.throwDetritus(this.currentLaunchedDetritus, direction, clampedForce);
        }

        this.hideTrajectoryPoints();
    }

    private getVelocityVector(position: THREE.Vector2): THREE.Vector3 {
        const dir = new THREE.Vector2().subVectors(position, this.swipeFrom);
        let yAngle = Math.acos(dir.y / dir.length()) * Math.sign(dir.x) * (window.innerWidth / window.innerHeight);
        const direction = new Vector3(0, 0, -1);

        const xAngle = new THREE.Vector3(1, 0, 0);
        const angle = Game.config.throwXAngle * Math.PI / 180;
        direction.applyAxisAngle(xAngle, angle);

        const yAxis = new THREE.Vector3(0, -1, 0);
        yAngle = clamp(yAngle, -Math.PI / 3, Math.PI / 3);
        direction.applyAxisAngle(yAxis, yAngle);

        return direction;
    }

    private throwDetritus(detritus: Detritus, direction: THREE.Vector3, strengh: number): void {
        const autoAimTarget: THREE.Vector3 = this.checkAutoAimTarget(detritus.Type);

        if (autoAimTarget != null) {
            direction = this.autoAim(autoAimTarget);
        }

        detritus.throw(strengh);
        detritus.rigidbody.setGravity(new Ammo.btVector3(0, GamePhysics.gravity.y(), 0));
        detritus.rigidbody.setLinearVelocity(new Ammo.btVector3(direction.x, direction.y, direction.z));
        this.onDetritusLaunched.dispatch(detritus);
        this.currentLaunchedDetritus = null;

        this.audioManager.playSound(this.THROW_SOUND, 1);
    }

    private autoAim(targetPosition: THREE.Vector3): THREE.Vector3 {
        //const targetPosition = new THREE.Vector3(-1.2, 0.2499999850988388 + 0.18, -5.5);
        const targetPlanarPosition = new THREE.Vector3(targetPosition.x, 0, targetPosition.z);

        const position = new THREE.Vector3().copy(this.defaultDetritusPosition);
        const planarPosition = new THREE.Vector3(position.x, 0, position.z);

        const distance = planarPosition.distanceTo(targetPlanarPosition);
        const yOffset = (position.y - targetPosition.y);

        const angle = Game.config.throwXAngle;
        const radianAngle = MathUtils.degToRad(angle);

        // Magical equation
        const initialVelocity = (1 / Math.cos(radianAngle)) * Math.sqrt((0.5 * 9.81 * Math.pow(distance, 2)) / (distance * Math.tan(radianAngle) + yOffset));
        const velocity = new THREE.Vector3(0, initialVelocity * Math.sin(radianAngle), initialVelocity * Math.cos(radianAngle));

        const diffPlanar = targetPlanarPosition.sub(planarPosition);
        const forward = new THREE.Vector3(0, 0, 1);
        const angleBetweenObjects = forward.angleTo(diffPlanar) * (targetPosition.x > position.x ? 1 : -1);

        return velocity.applyAxisAngle(new THREE.Vector3(0, 1, 0), angleBetweenObjects);
    }

    private drawTrajectory(): void {
        if (!this.showTrajectory || this.aimingVector === undefined) {
            return;
        }

        const trajectoryCollisionPoints: THREE.Vector3[] = this.computeTrajectoryPoints(60, this.trajectoryCollisionPoints.length);
        for (let i = 0; i < trajectoryCollisionPoints.length; i++) {
            this.trajectoryCollisionPoints[i].setPosition(trajectoryCollisionPoints[i]);
        }

        const trajectoryPoints: THREE.Vector3[] = this.computeTrajectoryPoints(100, this.trajectoryPoints.length);
        for (let i = 0; i < trajectoryPoints.length; i++) {
            this.trajectoryPoints[i].setPosition(trajectoryPoints[i]);
        }
    }

    private computeTrajectoryPoints(simulationNumber: number, points: number): THREE.Vector3[] {
        const step = 1 / simulationNumber;

        const lastPosition = new THREE.Vector3();
        lastPosition.copy(this.launchPosition);

        const velocity = new THREE.Vector3();
        velocity.copy(this.aimingVector);
        velocity.multiplyScalar(step);

        const gravity = new THREE.Vector3(0, GamePhysics.gravity.y() / simulationNumber, 0);
        gravity.multiplyScalar(step);

        const trajectoryPoints: THREE.Vector3[] = [];

        for (let i = 0; i < points; i++) {
            const velocityVector = new THREE.Vector3();
            velocityVector.copy(velocity);
            velocityVector.add(gravity);
            velocity.copy(velocityVector);

            const newPos = lastPosition.add(velocityVector);
            trajectoryPoints.push(new THREE.Vector3().copy(newPos));
            lastPosition.copy(newPos);
        }

        return trajectoryPoints;
    }

    private checkAutoAimTarget(detritusType: DetritusType): THREE.Vector3 {
        let target: Vector3 = null;
        for (let i = 0; i < this.trajectoryCollisionPoints.length; i++) {
            const dustbin: Dustbin = this.dustbins[0];
            const goalPositionY = dustbin.GoalPosition.y;
            // Check only the point under the target goal
            if (this.trajectoryCollisionPoints[i].getPosition().y <= goalPositionY) {
                for (let j = 0; j < this.dustbins.length; j++) {
                    if (this.dustbins[j].GoalPosition.distanceTo(this.trajectoryCollisionPoints[i].getPosition()) <= Game.config.aimBotGoalDistance && (this.dustbins[j].Type == detritusType || this.dustbins[j].Type == DetritusType.Household)) {
                        if(this.dustbins[j].Type == detritusType){
                            target = this.dustbins[j].GoalPosition;
                            break;
                        } else if (target == null){
                            target = this.dustbins[j].GoalPosition;
                        }
                    }
                }
            }
        }
        return target;
    }

    private displayTrajectoryPoints(throwForce: number) {
        for (let i = 0; i < this.trajectoryPoints.length; i++) {
            this.trajectoryPoints[i].show();
        }
    }

    private hideTrajectoryPoints() {
        this.showTrajectory = false;
        for (let i = 0; i < this.trajectoryPoints.length; i++) {
            this.trajectoryPoints[i].hide();
        }
    }

    private onGameStateChanged(state: GameState) {
        if (state === GameState.End || state === GameState.Pause) {
            this.isLaunchAllowed = false;
            this.isSwiping = false;
            document.body.classList.remove("no-cursor");
            this.hideTrajectoryPoints();
            if (this.currentLaunchedDetritus != null) {
                this.currentLaunchedDetritus.destroy();
                this.currentLaunchedDetritus = null;
            }
        } else if (state === GameState.Started ){
            this.isLaunchAllowed = true;
        }
    }

    private onDustbinsLayoutChanged(dustbins: Dustbin[]) {
        this.dustbins = dustbins;
    }

    get detritusLaunched(): ISimpleEvent<Detritus> {
        return this.onDetritusLaunched.asEvent();
    }
}