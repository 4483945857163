import * as THREE from "three";

export default class DetritusParticle {

    private mainScene: THREE.Scene;
    private particle: THREE.Mesh;
    private material: THREE.MeshBasicMaterial;
    private geometry: THREE.CircleGeometry;

    private radius = 0.18;
    private lifeTime = 0.6;
    private scaleReducer = 0.02;
    private opacityReducer = 1;
    private destroyed = false;

    constructor(mainScene: THREE.Scene, position: THREE.Vector3,size:number, lifeTime:number, scaleReducer: number, opacityReducer: number)  {
        this.mainScene = mainScene;
        this.radius = size;
        this.lifeTime = lifeTime;
        this.scaleReducer = scaleReducer;
        this.opacityReducer = opacityReducer;

        this.geometry = new THREE.CircleGeometry( this.radius, 30 );
        this.material = new THREE.MeshBasicMaterial( { color: 0xDFDFDF, transparent: true, opacity: 0.7 } );
        this.particle = new THREE.Mesh( this.geometry, this.material );
        this.particle.position.copy(position);
        mainScene.add(this.particle);
    }

    destroy(): void {
        this.mainScene.remove(this.particle);
        this.particle = null;
        this.material = null;
        this.geometry = null;
    }

    update(deltaTime: number) : void {
        if(this.destroyed){
            return;
        }
        this.material.opacity -= deltaTime *  this.opacityReducer;
        this.geometry.scale(1 - this.scaleReducer, 1 - this.scaleReducer, 1);
        this.lifeTime -= deltaTime;
        if(this.lifeTime <= 0){
            this.destroyed = true;
            this.destroy();
        }
    }
}