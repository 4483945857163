import * as THREE from "three";

export default class TrajectoryPoint {

    private mesh:THREE.Mesh;

    constructor(mainScene: THREE.Scene) {
        const geometry = new THREE.CircleGeometry(0.008, 30);
        const material = new THREE.MeshBasicMaterial({color: 0xADFF0D, transparent: false, opacity:1});
        this.mesh = new THREE.Mesh(geometry, material);
        this.mesh.position.copy(new THREE.Vector3(0, -50, 0));
        mainScene.add(this.mesh);
    }

    update(deltaTime: number, secs: number) : void {
    }

    show(): void {
        this.getMaterial().visible = true;
    }

    hide(): void {
        this.getMaterial().visible = false;
    }

    setPosition(position: THREE.Vector3) : void {
        this.mesh.position.copy(position);
    }

    private getMaterial() : THREE.Material {
        // @ts-ignore
        return this.mesh.material;
    }
}