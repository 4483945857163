import * as THREE from "three";
import DetritusParticle from "./DetritusParticle";

export default class ParticlesEmitter {

    private follow: THREE.Object3D;
    private mainScene: THREE.Scene;
    private isStarted: boolean;
    private particles:DetritusParticle[];

    // Particles properties
    private size:number;
    private lifeTime:number;
    private scaleReducer:number;
    private opacityReducer:number;

    /**
     * Ctor.
     * @param mainScene
     * @param follow
     */
    constructor(mainScene: THREE.Scene, follow: THREE.Object3D) {
        this.particles = [];
        this.follow = follow;
        this.mainScene = mainScene;
    }

    update(deltaTime: number): void {
        this.emit();
        for(let i = 0; i < this.particles.length; i++){
            this.particles[i].update(deltaTime);
        }
    }

    start(size:number, lifeTime:number, scaleReducer: number, opacityReducer:number): void {
        this.scaleReducer = scaleReducer;
        this.size = size;
        this.lifeTime = lifeTime;
        this.opacityReducer = opacityReducer;
        this.isStarted = true;
    }

    stop(): void {
        this.isStarted = false;
    }

    destroy(): void {
        this.isStarted = false;
        for(let i = 0; i < this.particles.length; i++){
            this.particles[i].destroy();
            this.particles[i] = null;
        }
        this.particles = [];
    }

    private emit():void {
        if(this.isStarted){
            this.particles.push(new DetritusParticle(this.mainScene, this.follow.position, this.size, this.lifeTime, this.scaleReducer, this.opacityReducer));
        }
    }
}