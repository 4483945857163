// Random integer from <low, high> interval
export function randInt(low: number, high: number): number {
    return low + Math.floor(Math.random() * (high - low + 1));
}

// Javascript mod fix
export function mod(n: number, m: number): number {
    return (n % m + m) % m;
}

export function clamp(num: number, min: number, max: number): number {
    return num <= min ? min : num >= max ? max : num;
}

export function randomEnum<T>(anEnum: T): T[keyof T] {
    const enumValues = Object.keys(anEnum)
        .map(n => Number.parseInt(n))
        .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][]
    const randomIndex = Math.floor(Math.random() * enumValues.length)
    return enumValues[randomIndex];
}

export function getRandomInt(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min);
}

/**
 * TODO: This function could be improved...
 * @param min
 * @param max
 * @param arrayOfIndexesToExclude
 */
export function getRandomIntWithManyExclusions(min: number, max: number, arrayOfIndexesToExclude: number[] = []): number {
    let rand = null;
    let iterationMax = 100;
    while (rand === null || arrayOfIndexesToExclude.includes(rand)) {
        rand = getRandomInt(min, max);
        iterationMax--;
        if (iterationMax < 0) {
            console.warn("Itération max with min " + min + " : " + max);
            console.warn(arrayOfIndexesToExclude);
            break;
        }
    }
    return rand;
}

export function hideElement(elem: HTMLElement): void {
    elem.classList.remove("is-visible");
    elem.classList.remove("is-visible-flex");
    elem.classList.add("is-hidden");
}

export function showElement(elem: HTMLElement): void {
    elem.classList.remove("is-hidden");
    elem.classList.add("is-visible");
}

export function showFlexElement(elem: HTMLElement): void {
    elem.classList.remove("is-hidden");
    elem.classList.add("is-visible-flex");
}