import * as THREE from "three";
import {Object3D, Vector3} from "three";
import {DetritusType} from "./DetritusType";
import Ammo from "ammojs-typed";
import GamePhysics from "./GamePhysics";
import {Tags} from "./Tags";
import GameObject from "./GameObject";
import Detritus from "./Detritus";
import {ITexture} from "./ITexture";

export default class Dustbin extends GameObject {

    private detritusType: DetritusType;
    private dustbinObject: THREE.Group;
    private dustbinBox: THREE.Object3D;
    private enterDustbinBox: THREE.Object3D;
    private textures:ITexture;

    private collisionMesh: THREE.Mesh;

    private goalPosition: THREE.Vector3;
    private mainScene: THREE.Scene;
    protected TAG = Tags.dustbin;

    /**
     * Ctor.
     * @param scene
     * @param position
     * @param type
     * @param textures
     */
    constructor(scene: THREE.Scene, type: DetritusType, position: THREE.Vector3, textures: ITexture) {
        super();
        this.mainScene = scene;

        this.detritusType = type;
        this.textures = textures;

        // Creates sprite with his scale
        const spriteMap = new THREE.TextureLoader().load("textures/dustbins/dustbin-" + type + ".png");
        const spriteMaterial = new THREE.SpriteMaterial({map: spriteMap});
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(0.8, 0.8, 1)

        // Creates group
        this.dustbinObject = new THREE.Group();
        this.dustbinObject.add(sprite);

        // Ammo.js : Create body
        this.dustbinBox = GamePhysics.createParalellepipedWithPhysics(0.8, 0.70, 1, 0, new THREE.Vector3(), new THREE.Quaternion(), 0x00000, true, this.dustbinObject, null);

        // Creates collider
        this.collisionMesh = new THREE.Mesh();
        //collisionMesh.userData.tag = this.TAG;
        this.collisionMesh.userData.gameObject = this;
        this.enterDustbinBox = GamePhysics.createParalellepipedWithPhysics(0.2, 0.05, 0.25, 0, new THREE.Vector3(), new THREE.Quaternion(), 0xFF0000, true, this.collisionMesh, this);

        scene.add(this.dustbinObject);

        this.goalPosition = new THREE.Vector3(position.x, position.y + 0.3, position.z);

        this.setPosition(this.dustbinBox, position);
        this.setPosition(this.enterDustbinBox, position.copy(this.goalPosition));
    }

    setPosition(object: Object3D, position: Vector3): void {
        object.position.copy(position);
        object.userData.rigidbody.getWorldTransform().setOrigin(new Ammo.btVector3(position.x, position.y, position.z));
    }

    update(deltaTime: number, secs: number): void {
        //console.log(this.dustbinObject.position);
    }

    destroy(): void {
        GamePhysics.removeRigidBody(this.dustbinObject);
        GamePhysics.removeRigidBody(this.enterDustbinBox);

        this.mainScene.remove(this.dustbinObject);
        this.mainScene.remove(this.collisionMesh);
        this.collisionMesh = null;
        this.dustbinObject = null;
    }

    get Type(): DetritusType {
        return this.detritusType;
    }

    get GoalPosition(): THREE.Vector3 {
        return this.goalPosition;
    }
}