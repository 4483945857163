import {hideElement, showElement} from "Utils";
import {GameConfig} from "../webgl/GameConfig";
import {Score} from "./Score";
import End from "./End";


export default class Leaderboard {

    private gameConfig: GameConfig = require("../config/config.json");

    private allTimeLeaderboardAPI = "/leaderboard";
    private dailyLeaderboardAPI = "/leaderboard/daily";
    private monthlyLeaderboardAPI = "/leaderboard/monthly";
    private weeklyLeaderboardAPI = "/leaderboard/weekly";
    private leaderboardTable = document.getElementById("leaderboard-table");
    private loadingElem = document.getElementById("leaderboard-loading");
    private noPlayerEleme = document.getElementById("leaderboard-no-players");
    private currentPlayerRank : Score;

    constructor() {

        document.body.classList.add("has-overflow-auto");
        const allTimeButton = document.getElementById("button-leaderboard-alltime");
        const dailyButton = document.getElementById("button-leaderboard-daily");
        const monthlyButton = document.getElementById("button-leaderboard-montly");
        const weeklyButton = document.getElementById("button-leaderboard-weekly");

        allTimeButton.addEventListener("click", (e: Event) => this.loadLeaderboard(this.allTimeLeaderboardAPI, e.currentTarget as Element, e));

        this.fetchLeaderboard(this.allTimeLeaderboardAPI);
    }

    private fetchLeaderboard(leaderboardURL: string) {

        this.clearLeaderboardTable();
        showElement(this.loadingElem);
        hideElement(this.noPlayerEleme);

        const leaderboard = this;
        fetch(process.env.BASE_URL + leaderboardURL, {
            headers: new Headers({
                "Authorization": "Basic " + btoa(process.env.API_AUTH),
            }),
        })
            .then(
                function (response) {
                    if (response.status !== 200) {
                        console.warn("Looks like there was a problem. Status Code: " +
                            response.status);
                        return;
                    }

                    // Examine the text in the response
                    response.json().then(function (data) {
                        if(data == null || data.length == 0){
                            showElement(leaderboard.noPlayerEleme);
                        } else {
                            hideElement(leaderboard.noPlayerEleme);
                        }
                        hideElement(leaderboard.loadingElem);
                        leaderboard.displayLeaderboardEntries(data);
                        leaderboard.displayCurrentPlayerRank();
                    });
                }
            )
            .catch(function (err) {
                console.error("Fetch Error :-S", err);
            });
    }

    displayCurrentPlayerRank() : void{
        if(this.currentPlayerRank != null){
            showElement(document.getElementById("leaderboard-current-player-rank-container"));
            document.getElementById("leaderboard-current-player-rank").innerHTML = this.currentPlayerRank.rank.toString();
            document.getElementById("leaderboard-current-player-nickname").innerHTML = this.currentPlayerRank.nickname;
            document.getElementById("leaderboard-current-player-score").innerHTML = this.currentPlayerRank.score.toString();
        }
    }

    displayLeaderboardEntries(data: Score[]): void {
        for (let i = 0; i < data.length; i++) {
            const tableRow = document.createElement("tr");

            if(data[i].email == localStorage.getItem(End.localStorageEmailKey)) {
                tableRow.classList.add("is-player");
                this.currentPlayerRank = data[i];
            }

            // rank
            const rank = document.createElement("td");
            rank.classList.add("leaderboard-rank");
            rank.innerHTML = data[i].rank.toString();

            // nickname
            const nickname = document.createElement("td");
            nickname.classList.add("leaderboard-nickname");
            nickname.innerHTML = data[i].nickname;

            // score
            const score = document.createElement("td");
            score.classList.add("leaderboard-score");
            score.innerHTML = data[i].score.toString();

            // append table cell to table row
            tableRow.appendChild(rank);
            tableRow.appendChild(nickname);
            tableRow.appendChild(score);

            // append table row to table body
            this.leaderboardTable.appendChild(tableRow);
        }
    }

    loadLeaderboard(leaderboard: string, button: Element, event: Event): void {
        event.preventDefault();
        const elements = document.getElementsByClassName("button-leaderboard");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("is-active");
        }
        button.classList.add("is-active");
        this.fetchLeaderboard(leaderboard);
    }

    private clearLeaderboardTable() {
        while (this.leaderboardTable.hasChildNodes()) {
            this.leaderboardTable.removeChild(this.leaderboardTable.firstChild);
        }
    }
}