/*
 * ThreeJSApp.ts
 * ===========
 * Entry from Webpack, generates Three.js View
 */
import "./stylesheets/main.scss";
import Game from "./webgl/Game";
import {GameConfig} from "./webgl/GameConfig";
import Ammo from "ammojs-typed";
import THREE, {Clock} from "three";
import Leaderboard from "./views/Leaderboard";
import Intro from "./views/Intro";

export default class ThreeJSApp {
    private game: Game;
    private gameConfig: GameConfig = require("./config/config.json");
    private clock:THREE.Clock;

    constructor() {
        Ammo(Ammo).then(() => {
            this.init();
        })
    }

    private init(){
        this.clock = new Clock();
        this.game = new Game(this.gameConfig);
        //this.stats = Stats();
        //document.body.appendChild(this.stats.dom);
        this.update(0);
    }

    public start(){
        this.game.start();
    }

    private update = (t: number): void => {
        const deltaTime:number = this.clock.getDelta();
        const tSec = t / 1000;
        this.game.update(deltaTime, tSec);
        //this.stats.update();
        requestAnimationFrame(this.update);
    }

}

const tulas = new Intro();

const canvasBox = <HTMLCanvasElement>document.getElementById("webgl-canvas");
if(canvasBox != null){
    //const app = new ThreeJSApp();
} else {
    const leaderboard = new Leaderboard();
}
