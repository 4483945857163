import {hideElement, showElement} from "Utils";
import {GameConfig} from "../webgl/GameConfig";
import {Score} from "./Score";
import Ammo from "ammojs-typed";
import btActionInterface = Ammo.btActionInterface;
import {Md5} from "md5-typescript";
import * as crypto from "crypto-js";

export default class End {

    private scoreField = document.getElementById("score-field") as HTMLInputElement;
    private scoreSubmissionView = document.getElementById("score-submission");

    // Local storage keys
    public static localStorageNicknameKey = "rosepom_nickname";
    public static localStorageEmailKey = "rosepom_email";

    // Layouts
    private static savedScoreLayout = "saved-score-layout";
    private static savedScoreLayoutHighscore = "saved-highscore-layout";
    private static loadingLayout = "loading-layout";
    private static subscriptionLayout = "subscription-layout";

    // Fields
    private nicknameField = document.getElementById("nickname-field") as HTMLInputElement;
    private emailField = document.getElementById("email-field") as HTMLInputElement;
    private form = document.getElementById("submit-score-form");

    private score: number;
    private scoresHistory: number[];
    private playedTime: number;
    private config: GameConfig;

    constructor(score: number, scoresHistory: number[], playedTime:number, config: GameConfig) {

        document.body.classList.remove("in-game");
        showElement(this.scoreSubmissionView);
        this.score = score;
        this.scoresHistory = scoresHistory;
        this.playedTime = playedTime;
        this.config = config;

        this.scoreSubmissionView.getElementsByTagName("span")[0].innerHTML = String(score);
        this.saveScore(score);

        this.form.addEventListener("submit", (e: Event) => this.submitForm(e));
    }

    private saveScore(score: number): void {

        const email: string = localStorage.getItem(End.localStorageEmailKey);
        const nickname: string = localStorage.getItem(End.localStorageNicknameKey);
        this.scoreField.value = score.toString();

        if (email != null && nickname != null) {
            this.emailField.value = email;
            this.nicknameField.value = nickname;
            this.sendScore(false);
        } else {
            this.displaySubscription()
        }
    }

    private displaySubscription() {
        this.setLayout(End.subscriptionLayout);
    }

    public displaySavedScore(newHighscore: boolean, score: number, rank: number): void {
        const x = document.getElementsByClassName("score-player-rank");
        for (let i = 0; i < x.length; i++) {
            x[i].innerHTML = rank == 1 ? rank + "er" : rank + "ème";
        }

        const s = document.getElementById("score-highscore")
        s.innerHTML = score.toString();

        this.setLayout(newHighscore ? End.savedScoreLayoutHighscore : End.savedScoreLayout);
    }

    private displayLoading(): void {
        this.setLayout(End.loadingLayout);
    }

    private setLayout(layout: string) {
        this.scoreSubmissionView.classList.remove(End.subscriptionLayout);
        this.scoreSubmissionView.classList.remove(End.savedScoreLayout);
        this.scoreSubmissionView.classList.remove(End.savedScoreLayoutHighscore);
        this.scoreSubmissionView.classList.remove(End.loadingLayout);
        this.scoreSubmissionView.classList.add(layout);
    }

    private submitForm(e: Event): void {
        e.preventDefault();
        this.sendScore(true);
    }

    private sendScore(subscribe: boolean): void {
        // @ts-ignore
        const data = Object.fromEntries(new FormData(this.form).entries());
        const payload = {};
        payload["FdyQFgXSxWcc9MfR4PLQ"] = this.scoresHistory;
        payload["RyRC5wNyWAuvVWn6tEsP"] = Md5.init(this.config);
        payload["f3erg2MCyvEpzYcUKvSw"] = this.playedTime;
        payload["8arnEQ5rFbCxSghjj4nT"] = this.score;

        //The key and iv should be 32 hex digits each, any hex digits you want,
        //but it needs to be 32 on length each
        const key = crypto.enc.Hex.parse("40ae71633911887a8b6a537f6fbb2cdd");
        const iv =  crypto.enc.Hex.parse("acd09999c865d9ef0fdbae6631ea181b");
        const encrypted = crypto.AES.encrypt(JSON.stringify(payload), key, {iv:iv, padding: crypto.pad.ZeroPadding, mode: crypto.mode.CBC});
        data["payload"] = encrypted.ciphertext.toString(crypto.enc.Base64);

        const self: End = this;

        this.displayLoading();


        fetch(process.env.BASE_URL + "/score", {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": "Basic " + btoa(process.env.API_AUTH),
                "Accept": "application/json",
                "Content-Type": "application/json"
            })
        }).then(function (res) {
            return res.json();
        }).then(function (data: Score) {
            if(data.nickname != undefined && data.email != undefined){
                localStorage.setItem(End.localStorageNicknameKey, data.nickname);
                localStorage.setItem(End.localStorageEmailKey, data.email);
            }
            if (subscribe) {
                window.location.href = "./leaderboard.html";
            } else {
                self.displaySavedScore(data.score == self.score, data.score, data.rank);
            }
        })
    }
}