import {hideElement, randInt, showElement} from "Utils";
import {GameConfig} from "../webgl/GameConfig";
import {Score} from "./Score";
import Ammo from "ammojs-typed";
import btActionInterface = Ammo.btActionInterface;
import ThreeJSApp from "../ThreeJSApp";

export default class Intro {

    // Fields
    private button = document.getElementById("start-game") as HTMLInputElement;
    private intro = document.getElementById("intro-view") as HTMLInputElement;
    private introContent = document.getElementById("intro-view-content") as HTMLInputElement;
    private tip = document.getElementById("did-you-know-content") as HTMLInputElement;
    private tips: string[] = require("../resources/infotips.json");
    private app: ThreeJSApp;
    private started:boolean;

    constructor() {
        if(this.intro != null){
            this.app = new ThreeJSApp();
            this.tip.innerText = this.tips[randInt(0, this.tips.length - 1)];
            this.button.addEventListener("click", (e: Event) => this.startGame(e.currentTarget as Element, e));
            this.button.addEventListener("touchstart", (e: Event) => this.startGame(e.currentTarget as Element, e));
            this.introContent.classList.add("is-visible");

            this.intro.addEventListener("click", (e: Event) => {
                e.stopPropagation();
            });
            this.intro.addEventListener("pointerdown", (e: Event) => {
                e.stopPropagation();
            });
            this.intro.addEventListener("touchstart", (e: Event) => {
                e.stopPropagation();
            });
        }
    }

    startGame(elem : Element, event: Event): void {
        if(this.started){
            return;
        }

        this.app.start();
        this.started = true;
        this.button.disabled = true;
        this.introContent.classList.remove("is-visible");

        document.body.classList.add("in-game");

        setTimeout(() => {
            this.intro.classList.add("is-hidden-opacity");
        }, 200);

        setTimeout(() => {
            hideElement(this.intro);
        }, 500);
    }
}