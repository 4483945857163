import {ISimpleEvent} from "ste-simple-events/dist/definitions";
import {SimpleEventDispatcher} from "strongly-typed-events";
import Game from "../Game";
import {ILevel} from "./ILevel";
import {clamp} from "../../utils";
import ScoreChangedEventArgs from "../events/ScoreChangedEventArgs";

export default class Score {

    private score: number;
    private onScoreChanged = new SimpleEventDispatcher<ScoreChangedEventArgs>();
    private onLevelChanged = new SimpleEventDispatcher<ILevel>();
    private onComboChanged = new SimpleEventDispatcher<number>();
    private currentLevelIndex = 0;
    private currentLevel: ILevel;
    private combo: number;

    private scoresHistory: number[];

    /**
     * Ctor.
     */
    constructor() {
        this.score = 0;
        this.scoresHistory = [];
        this.currentLevel = Game.config.levels[this.currentLevelIndex];
        this.resetCombo();
    }

    enterCorrectDustbin(comboEnabled: boolean): void {
        if(comboEnabled){
            this.addCombo();
        }
        this.addScore(Game.config.scorePerSuccess);
    }

    enterWrongDustbin(): void {
        this.resetCombo();
        this.addScore(Game.config.scorePerMistake);
    }

    enterHousehold(): void {
        this.resetCombo();
    }

    missDustbin(): void {
        this.resetCombo();
        this.addScore(Game.config.scorePerMiss, true);
    }

    addCombo(): void {
        this.combo++;
        this.combo = clamp(this.combo, 0, Game.config.comboMax);
        if(this.combo > 1){
            this.onComboChanged.dispatch(this.combo);
        }
    }

    resetCombo(): void  {
        this.combo = 0;
        this.onComboChanged.dispatch(this.combo);
    }

    addScore(score: number, hasMiss = false): void {
        const combo = clamp(this.combo, 1, Game.config.comboMax);
        this.scoresHistory.push(Math.sign(score) > 0 ? combo : score);
        const newScore = score * combo;
        this.score += newScore;

        const nextLevelIndex = clamp(this.currentLevelIndex + 1, 0, Game.config.levels.length - 1);
        const nextLevel = Game.config.levels[clamp(nextLevelIndex, 0, Game.config.levels.length - 1)];

        if (this.score >= nextLevel.score && nextLevelIndex > this.currentLevelIndex) {
            this.currentLevelIndex = nextLevelIndex;
            this.currentLevel = nextLevel;
        }

        this.onScoreChanged.dispatch(new ScoreChangedEventArgs(this.score, newScore, hasMiss));
    }

    startJokerPhase(): void {
        this.resetCombo();
        this.currentLevel = Game.config.jokerLevel;
    }

    get scoreChanged(): ISimpleEvent<ScoreChangedEventArgs> {
        return this.onScoreChanged.asEvent();
    }

    get comboChanged(): ISimpleEvent<number> {
        return this.onComboChanged.asEvent();
    }

    get levelChanged(): ISimpleEvent<ILevel> {
        return this.onLevelChanged.asEvent();
    }

    get CurrentLevel(): ILevel {
        return this.currentLevel;
    }

    get Score(): number {
        return this.score;
    }

    get ScoresHistory(): number[]{
        return this.scoresHistory;
    }
}