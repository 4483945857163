import * as THREE from "three";
import {DetritusType} from "./DetritusType";
import Ammo from "ammojs-typed";
import GamePhysics from "./GamePhysics";
import {Tags} from "./Tags";
import {EventDispatcher, ISignal, SignalDispatcher} from "strongly-typed-events";
import Dustbin from "./Dustbin";
import {IEvent} from "ste-events/dist/definitions";
import Floor from "../view/Floor";
import ParticlesEmitter from "./ParticlesEmitter";
import {clamp, getRandomInt, randInt} from "Utils";
import GameObject from "./GameObject";
import AudioManager from "../view/AudioManager";
import Game from "../Game";
import {ITexture} from "./ITexture";

export default class Detritus extends GameObject {

    private onEnterDustbin = new EventDispatcher<Detritus, Dustbin>();
    private onMissDustbin = new SignalDispatcher();
    protected TAG = Tags.detritus;
    private launchedItem: THREE.Group;
    private body: Ammo.btRigidBody;
    private mainScene: THREE.Scene;
    private detritusType: DetritusType;
    private missed: boolean;
    private particlesEmitter: ParticlesEmitter;
    private detritusBox: THREE.Object3D;
    private textures: ITexture;

    // sounds
    private readonly IN_SOUND = "in-3.mp3";
    private readonly MISS_SOUND = "miss.mp3";
    private audioManager: AudioManager;
    private audioSource: THREE.Audio;

    // FX
    private isShowingFX = false;
    private isHiddingFX = false;
    private smokeFX: THREE.Sprite;

    /**
     * Ctor.
     * @param mainScene
     * @param detritusType
     * @param position
     * @param audioManager
     * @param textures
     */
    constructor(mainScene: THREE.Scene, detritusType: DetritusType, position: THREE.Vector3, audioManager: AudioManager, textures: ITexture) {

        super();
        this.mainScene = mainScene;
        this.detritusType = detritusType;
        this.audioManager = audioManager;
        this.textures = textures;
        //this.audioSource = new THREE.Audio(audioManager.AudioListener);

        const type: string = this.getDetritusByType(detritusType);

        let sprite;
        if (type != undefined) {
            const spriteMap = textures[type];
            const spriteMaterial = new THREE.SpriteMaterial({map: spriteMap});
            sprite = new THREE.Sprite(spriteMaterial);
            const ratio = spriteMap.image.width / spriteMap.image.height;
            if (ratio > 1) {
                sprite.scale.set(0.2, 0.2 / ratio, 1);
            } else {
                sprite.scale.set(0.2 * ratio, 0.2, 1);
            }
        }

        this.launchedItem = new THREE.Group();
        if (type != undefined) {
            this.launchedItem.add(sprite);
        }

        this.particlesEmitter = new ParticlesEmitter(this.mainScene, this.launchedItem);
        const cubeWidth = 0.15;
        const color = 0x30449B;

        //const vect = new THREE.Vector3(position.x, position.y + sprite.scale.y, position.z);

        this.detritusBox = GamePhysics.createParalellepipedWithPhysics(cubeWidth * 0.7, cubeWidth, cubeWidth * 0.7, 0.1, position, new THREE.Quaternion(), color, false, this.launchedItem, this);
        // @ts-ignore
        this.detritusBox.children[0].material.visible = true;
        this.body = this.detritusBox.userData.rigidbody;

    }

    private getDetritusByType(type: DetritusType): string {
        let detritus = "";
        for (let i = 0; i < Game.config.detritus.length; i++) {
            const def = Game.config.detritus[i];
            if (def.type == type) {
                detritus = def.images[getRandomInt(0, Game.config.detritus[i].images.length - 1)];
            }
        }
        return detritus;
    }

    /**
     * Catch collisions
     * @param deltaTime
     * @param secs
     */
    update(deltaTime: number, secs: number): void {
        this.checkCollisions();
        if (this.launchedItem != null) {
            this.particlesEmitter.update(deltaTime);
        }

        if(this.isShowingFX){
            this.displayFX(deltaTime);
        }
        if(this.isHiddingFX){
            this.hideFX(deltaTime);
        }
    }

    /**
     * Checks collisions
     * @private
     */
    private checkCollisions(): void {
        const dispatcher = GamePhysics.collisionDispatcher;
        const numManifolds = dispatcher.getNumManifolds();
        for (let i = 0; i < numManifolds; i++) {
            const contactManifold: Ammo.btPersistentManifold = dispatcher.getManifoldByIndexInternal(i);
            const rb0 = GamePhysics.castManifoldToBody(contactManifold.getBody0());
            const rb1 = GamePhysics.castManifoldToBody(contactManifold.getBody1());

            const mesh0: THREE.Object3D = GamePhysics.threeJSObjectFromBody(rb0);
            const mesh1: THREE.Object3D = GamePhysics.threeJSObjectFromBody(rb1);

            if (mesh0 != this.launchedItem && mesh1 != this.launchedItem) {
                continue;
            }

            const go0: GameObject = mesh0.userData.gameObject;
            const go1: GameObject = mesh1.userData.gameObject;

            if (go0 instanceof Detritus && go1 instanceof Dustbin || go1 instanceof Detritus && go0 instanceof Dustbin) {
                this.onEnterDustbin.dispatch(this, go0 instanceof Dustbin ? go0 as Dustbin : go1 as Dustbin);
                this.enter();
            } else if (go0 instanceof Floor && go1 instanceof Detritus || go1 instanceof Floor && go0 instanceof Detritus) {
                if (!this.missed) {
                    this.miss();
                }
            }
        }
    }

    private enter(): void {
        this.audioManager.playSound(this.IN_SOUND, 0.5, false);
        this.destroy();
    }

    /**
     * The detritus miss a dustbin
     * @private
     */
    private miss(): void {
        this.missed = true;
        this.particlesEmitter.stop();
        this.onMissDustbin.dispatch();
        this.audioManager.playSound(this.MISS_SOUND, 0.5, false);
        setTimeout(() => {
            this.destroy();
        }, 500)
    }

    /**
     * Destroys the detritus and his emitter
     * @private
     */
    destroy(noFx = false): void {
        GamePhysics.removeRigidBody(this.detritusBox);
        if (this.launchedItem != null) {
            const selectedObject = this.mainScene.getObjectById(this.launchedItem.id);
            this.mainScene.remove(selectedObject);
            if(!noFx){
                this.createFXs();
            }
        }

        this.launchedItem = null;
        this.particlesEmitter.destroy();
        this.particlesEmitter = null;

        setTimeout(() => {
            this.mainScene.remove(this.smokeFX);
            this.smokeFX = null;
        }, 2000)
    }

    /**
     * Throw the object
     * @param strengh
     */
    throw(strengh: number): void {

        if (this.launchedItem == null) {
            return;
        }

        const sizeMax = 0.013;
        const strenghMax = 12;
        const strenghMin = 2;

        const minOpacityReducer = 5;
        const maxOpacityReducer = 15;
        const trailLength = 3;
        const opacityReducer = clamp(maxOpacityReducer - ((strengh * minOpacityReducer / strenghMin) * trailLength), minOpacityReducer, maxOpacityReducer);
        this.particlesEmitter.start(Math.pow(strengh, 1.7) / 1.1 * sizeMax / strenghMax, 0.3, 0.02, opacityReducer);

        setTimeout(() => {
            if(this.launchedItem != null){
                this.miss();
            }
        }, 3000)
    }

    private createFXs(): void {
        const spriteMaterial = new THREE.SpriteMaterial(
            {
                map: this.textures["smoke.png"],
                rotation: Math.PI / randInt(1, 6),
            });
        this.smokeFX = new THREE.Sprite(spriteMaterial);
        this.smokeFX.material.opacity = 0;
        this.smokeFX.position.copy(new THREE.Vector3(this.launchedItem.position.x, this.launchedItem.position.y, this.launchedItem.position.z));
        this.smokeFX.scale.set(0.4, 0.4, 1);
        this.mainScene.add(this.smokeFX);
        this.isShowingFX = true;
    }

    private displayFX(deltaTime: number): void {
        if(this.smokeFX != null){
            this.smokeFX.material.opacity += Math.pow(deltaTime, 2) * 750;
            this.smokeFX.scale.set(this.smokeFX.scale.x += deltaTime * 0.4, this.smokeFX.scale.y += deltaTime * 0.4, 1);
            if( this.smokeFX.material.opacity >= 1){
                this.isShowingFX = false;
                this.isHiddingFX = true;
            }
        }
    }

    private hideFX(deltaTime: number): void {
        if(this.smokeFX != null){
            this.smokeFX.material.opacity -= Math.pow(deltaTime, 2) * 400;
            //this.smokeFX.scale.set(this.smokeFX.scale.x -= deltaTime * 2, this.smokeFX.scale.y -= deltaTime * 2, 1);
            if( this.smokeFX.material.opacity <= 0){
                this.isShowingFX = false;
                this.isHiddingFX = false;
            }
        }
    }

    /**
     * Returns the main scene
     */
    get rigidbody(): Ammo.btRigidBody {
        return this.body;
    }

    /**
     * Throw an event when the detritus miss a dustbin
     */
    get missDustbin(): ISignal {
        return this.onMissDustbin.asEvent();
    }

    /**
     * Throw an event when the detritus enter a dustbin
     */
    get enterDustbin(): IEvent<Detritus, Dustbin> {
        return this.onEnterDustbin.asEvent();
    }

    /**
     * Return types
     */
    get Type(): DetritusType {
        return this.detritusType;
    }

    get gameObject(): THREE.Object3D {
        return this.launchedItem;
    }
}