import * as THREE from "three";
import Ammo from "ammojs-typed";
import Game from "../Game";
import GameObject from "./GameObject";

export default class GamePhysics {

    private transformAux1: Ammo.btTransform;
    // Rigid bodies include all movable objects
    public static physicsWorld: Ammo.btDiscreteDynamicsWorld;
    public static gravity: Ammo.btVector3;
    private static rigidBodies: THREE.Object3D[] = [];
    private static mainScene: THREE.Scene;
    private static dispatcher: Ammo.btCollisionDispatcher;

    /**
     * Ctor.
     */
    constructor(mainScene: THREE.Scene) {
        GamePhysics.gravity = new Ammo.btVector3(0, -9.81, 0);
        GamePhysics.mainScene = mainScene;
        const collisionConfiguration: Ammo.btDefaultCollisionConfiguration = new Ammo.btDefaultCollisionConfiguration();
        GamePhysics.dispatcher = new Ammo.btCollisionDispatcher(collisionConfiguration);
        const broadphase: Ammo.btDbvtBroadphase = new Ammo.btDbvtBroadphase();
        const solver: Ammo.btSequentialImpulseConstraintSolver = new Ammo.btSequentialImpulseConstraintSolver();
        GamePhysics.physicsWorld = new Ammo.btDiscreteDynamicsWorld(GamePhysics.dispatcher, broadphase, solver, collisionConfiguration);
        GamePhysics.physicsWorld.setGravity(new Ammo.btVector3(0, GamePhysics.gravity.y(), 0));
        this.transformAux1 = new Ammo.btTransform();
    }

    /**
     * Update
     * @param deltaTime
     */
    update(deltaTime: number): void {
        GamePhysics.physicsWorld.stepSimulation(deltaTime, 10);

        // Update rigid bodies
        for (let i = 0, il = GamePhysics.rigidBodies.length; i < il; i++) {

            const objThree = GamePhysics.rigidBodies[i];
            const objPhys = objThree.userData.physicsBody;
            const ms = objPhys.getMotionState();

            if (ms) {
                ms.getWorldTransform(this.transformAux1);
                const p = this.transformAux1.getOrigin();
                const q = this.transformAux1.getRotation();


                objThree.position.set(p.x(), p.y(), p.z());
                objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());

                if (objThree.children.length > 0) {
                    for (let i = 0; i < objThree.children.length; i++) {
                        if (objThree.children[i] instanceof THREE.Sprite) {
                            const sprite: THREE.Sprite = objThree.children[i] as THREE.Sprite;
                            const a = new THREE.Euler();
                            a.setFromQuaternion(objThree.quaternion);
                            sprite.material.rotation = a.z;
                        }
                    }
                }

                objThree.userData.collided = false;
            }
        }
    }

    /**
     * Creates a rigidbody
     * @param object
     * @param physicsShape
     * @param mass
     * @param pos
     * @param quat
     * @param vel
     * @param angVel
     */
    static createRigidBody(object: THREE.Object3D, physicsShape: Ammo.btCollisionShape, mass: number, pos: THREE.Vector3, quat: THREE.Quaternion, vel: THREE.Vector3 = new THREE.Vector3(0, 0, 0), angVel: THREE.Vector3 = new THREE.Vector3(0, 0, 0)): Ammo.btRigidBody {

        if (pos) {
            object.position.copy(pos);
        } else {
            pos = object.position;
        }
        if (quat) {
            object.quaternion.copy(quat);
        } else {
            quat = object.quaternion;
        }

        const transform: Ammo.btTransform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        const motionState = new Ammo.btDefaultMotionState(transform);

        const localInertia: Ammo.btVector3 = new Ammo.btVector3(0, 0, 0);
        physicsShape.calculateLocalInertia(mass, localInertia);

        const rbInfo: Ammo.btRigidBodyConstructionInfo = new Ammo.btRigidBodyConstructionInfo(mass, motionState, physicsShape, localInertia);
        const body: Ammo.btRigidBody = new Ammo.btRigidBody(rbInfo);


        object.userData.physicsBody = body;

        body.setFriction(0.75);

        if (vel) {
            body.setLinearVelocity(new Ammo.btVector3(vel.x, vel.y, vel.z));
        }

        if (angVel) {
            body.setAngularVelocity(new Ammo.btVector3(angVel.x, angVel.y, angVel.z));
        }

        GamePhysics.linkThreeJsToRigidbody(body, object);
        object.userData.collided = false;

        GamePhysics.mainScene.add(object);

        if (mass > 0) {
            GamePhysics.rigidBodies.push(object);
            // Disable deactivation
            body.setActivationState(4);
        }

        GamePhysics.physicsWorld.addRigidBody(body);

        return body;
    }

    static removeRigidBody(object: THREE.Object3D) : void {
        const body: Ammo.btRigidBody = object.userData.physicsBody;
        const index = GamePhysics.rigidBodies.indexOf(object);
        GamePhysics.physicsWorld.removeRigidBody(body);
        if (index > -1) {
            GamePhysics.rigidBodies.splice(index, 1);
        }
    }

    static linkThreeJsToRigidbody(body: Ammo.btRigidBody, object: THREE.Object3D): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        body.threeJS = object;
    }

    /**
     * Creates a parallelepiped with physic
     * @param sx
     * @param sy
     * @param sz
     * @param mass
     * @param pos
     * @param quat
     * @param color
     * @param wireframe
     * @param parent
     * @param gameObject
     */
    static createParalellepipedWithPhysics(sx: number, sy: number, sz: number, mass: number, pos: THREE.Vector3, quat: THREE.Quaternion, color: number, wireframe: boolean, parent: THREE.Object3D, gameObject: GameObject): THREE.Object3D {
        const material = new THREE.MeshBasicMaterial({
            wireframe: wireframe && Game.config.showGizmos,
            visible: Game.config.showGizmos,
            color: color
        })

        const debugMesh = new THREE.Mesh(new THREE.BoxBufferGeometry(sx, sy, sz, 1, 1, 1), material);
        let objectToReturn: THREE.Object3D = debugMesh;

        if (parent != null) {
            parent.add(debugMesh);
            objectToReturn = parent;
        }

        const shape = new Ammo.btBoxShape(new Ammo.btVector3(sx * 0.5, sy * 0.5, sz * 0.5));
        shape.setMargin(0.05);
        const rigidbody: Ammo.btRigidBody = GamePhysics.createRigidBody(objectToReturn, shape, mass, pos, quat);
        objectToReturn.userData.rigidbody = rigidbody;
        objectToReturn.userData.gameObject = gameObject;

        return objectToReturn;
    }

    static castManifoldToBody(body: Ammo.btCollisionObject): Ammo.btRigidBody {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Ammo.castObject(body, Ammo.btRigidBody);
    }

    static threeJSObjectFromBody(body: Ammo.btRigidBody): THREE.Object3D {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return body.threeJS;
    }

    static get collisionDispatcher(): Ammo.btCollisionDispatcher {
        return GamePhysics.dispatcher;
    }
}