import GameView, {ScreenSizeType} from "./view/GameView";
import {GameConfig} from "./GameConfig";
import DetritusLauncher from "./logic/DetritusLauncher";
import Score from "./logic/Score";
import DustbinsManager from "./logic/DustbinsManager";
import GamePhysics from "./logic/GamePhysics";
import Detritus from "./logic/Detritus";
import Dustbin from "./logic/Dustbin";
import {SimpleEventDispatcher} from "strongly-typed-events";
import {GameState} from "./logic/GameState";
import {ISimpleEvent} from "ste-simple-events/dist/definitions";
import {ILevel} from "./logic/ILevel";
import {DetritusType} from "./logic/DetritusType";
import {getRandomInt} from "Utils";
import * as THREE from "three";
import AudioManager from "./view/AudioManager";
import {ITexture} from "./logic/ITexture";
import {Texture} from "three";
import {Md5} from "md5-typescript";

export default class Game {

    static config: GameConfig;
    private view: GameView;
    private dustbinManager: DustbinsManager;
    private score: Score;
    private gamePhysics: GamePhysics;
    private detritusLauncher: DetritusLauncher;
    private audioManager: AudioManager;
    private gameDuration: number;
    private playedTime: number;
    private gameStarted: boolean;

    private detritusTypes: DetritusType[];
    private onStateChanged = new SimpleEventDispatcher<GameState>();
    private launchesBeforeNewLayout: number;
    private jokerPhaseDetritus: Detritus[];
    private isJokerPhaseRunning: boolean;
    private paused: boolean;


    private readonly TICTAC_SOUND = "tic-tac.mp3";
    private timesUpAudioSource: THREE.Audio;

    private textureLoading = 0;
    private textureAmountLoaded = 0;
    private gameCreated: boolean;

    private textures : ITexture = {};

    constructor(config: GameConfig) {
        Game.config = config;
        this.loadTextures();
        //console.log(Md5.init(config));
    }

    loadTextures(): void {
        this.countTextures();
        this.loadDetritusTextures();
    }

    countTextures(): void {
        for(let i = 0; i < Game.config.detritus.length; i++){
            this.textureLoading += Game.config.detritus[i].images.length;
        }

        // +1 for dustbin effects
        this.textureLoading++;
    }

    loadDetritusTextures(): void {
        for(let i = 0; i < Game.config.detritus.length; i++){
            for(let j = 0; j < Game.config.detritus[i].images.length; j++){
                const spriteMap = new THREE.TextureLoader().load("textures/detritus/" + Game.config.detritus[i].images[j], () => {
                    this.textureLoaded(Game.config.detritus[i].images[j], spriteMap);
                });
            }
        }

        const spriteMap = new THREE.TextureLoader().load("textures/dustbins/FXs/smoke.png", () => {
            this.textureLoaded("smoke.png", spriteMap);
        });
    }

    textureLoaded(image: string, texture: Texture): void {
        this.textureAmountLoaded++;
        this.textures[image] = texture;
        if(this.textureAmountLoaded == this.textureLoading){
            this.createGame();
        }
    }

    start() {
        this.audioManager.init();
    }

    update(deltaTime: number, secs: number): void {
        if(!this.gameCreated) return;

        this.gamePhysics.update(deltaTime);
        this.view.update(deltaTime, secs);
        this.detritusLauncher.update(deltaTime, secs);
        this.dustbinManager.update(deltaTime, secs);

        if (this.gameStarted && !this.paused) {
            if (this.gameDuration < 0 && !this.isJokerPhaseRunning) {
                this.prepareJokerPhase();
            } else {
                this.playTimesUp();
                if(!this.isJokerPhaseRunning){
                    this.view.changeGameDuration(this.gameDuration -= deltaTime);
                }
            }
        }

        if(this.gameStarted){
            this.playedTime += deltaTime;
        }
    }

    createGame(): void  {
        this.jokerPhaseDetritus = [];
        this.launchesBeforeNewLayout = 0;
        this.gameDuration = Game.config.gameDuration;
        this.playedTime = 0;
        this.score = new Score();
        this.view = new GameView(this.score, this.audioManager);
        this.gamePhysics = new GamePhysics(this.view.mainScene);
        this.view.setupScene();
        this.audioManager = new AudioManager(this.view.mainCamera);

        this.dustbinManager = new DustbinsManager(this.view.mainScene, this.textures);

        this.detritusLauncher = new DetritusLauncher(this.view.mainScene, this, this.view.mainCamera, this.dustbinManager, this.audioManager, this.textures);
        this.detritusLauncher.detritusLaunched.subscribe((detritus: Detritus) => {
            this.onDetritusLaunchedHandler(detritus);
        });

        this.view.screenSizeTypeChanged.subscribe((type: ScreenSizeType) => {
            this.onScreenSizeTypeChanged(type);
        });

        //this.audioManager.playMusic(this.MAIN_MUSIC);
        this.gameCreated = true;
    }

    playTimesUp(): void {
        if (this.gameDuration <= 10) {
            if (this.timesUpAudioSource == null) {
                this.timesUpAudioSource = new THREE.Audio(this.audioManager.AudioListener);
                this.audioManager.playSound(this.TICTAC_SOUND, 1);
            }
        }
    }

    prepareJokerPhase(): void {
        if (this.jokerPhaseDetritus.length == 0) {
            this.endGame();
        } else {
            this.pauseGame();
            this.view.showJokerPhasePopup(this.jokerPhaseDetritus.length);
            setTimeout(() => {
                this.paused = false;
                this.startJokerPhase();
            }, 6500);
        }
    }

    startJokerPhase(): void {
        this.audioManager.playJokerMusic();
        this.isJokerPhaseRunning = true;
        this.gameDuration = 0;
        this.paused = false;
        this.onStateChanged.dispatch(GameState.Started);
        this.score.startJokerPhase();
        this.setupLayout(Game.config.jokerLevel);
    }

    private startGame(): void {
        this.gameStarted = true;
        this.view.hideStartupText();
    }

    private pauseGame(): void {
        this.paused = true;
        this.audioManager.stopSound(this.timesUpAudioSource);
        this.onStateChanged.dispatch(GameState.Pause);
    }

    private endGame(): void {
        this.gameStarted = false;
        this.onStateChanged.dispatch(GameState.End);
        this.audioManager.stopMusic();
        this.audioManager.stopSound(this.timesUpAudioSource);
        this.view.showResult( Game.config, this.playedTime);
    }

    private setupLayout(level: ILevel) {
        this.dustbinManager.refreshLayoutGrid(this.view.getCurrentScreenSizeType);
        this.detritusTypes = this.dustbinManager.setupDustbins(level);
        this.createNewDetritus(this.detritusTypes);
    }

    private createNewDetritus(detritusTypes: DetritusType[]) {
        const type: DetritusType = this.isJokerPhaseRunning ? this.pickDetritusTypeInJokerPhase() : detritusTypes[getRandomInt(0, detritusTypes.length - 1)];
        this.detritusLauncher.createDetritus(type);
    }

    private pickDetritusTypeInJokerPhase(): DetritusType {
        this.view.showJokerPhase(this.jokerPhaseDetritus.length);
        this.jokerPhaseDetritus.sort(function () {
            return 0.5 - Math.random();
        })
        const det: Detritus = this.jokerPhaseDetritus.pop();
        return det == null ? null : det.Type;
    }

    private onDetritusLaunchedHandler(detritus: Detritus) {
        if (!this.gameStarted) {
            this.startGame();
        }
        detritus.enterDustbin.subscribe((detritus: Detritus, dustbin: Dustbin) => {
            this.onDetritusEnterDustbin(detritus, dustbin);
        });

        detritus.missDustbin.subscribe(() => {
            this.onDetritusMissDustbin();
        });
    }

    private onDetritusEnterDustbin(detritus: Detritus, dustbin: Dustbin): void {
        if (detritus.Type === dustbin.Type) {
            this.gameDuration += Game.config.timeBonusPerSuccess;
            this.score.enterCorrectDustbin(!this.isJokerPhaseRunning);
        } else if (dustbin.Type == DetritusType.Household) {
            this.jokerPhaseDetritus.push(detritus);
            this.score.enterHousehold();
        } else {
            this.score.enterWrongDustbin();
        }
        this.prepareNewLaunch()
    }

    private prepareNewLaunch() {
        if (this.isJokerPhaseRunning) {
            if (this.jokerPhaseDetritus.length == 0) {
                this.endGame();
                return;
            } else {
                this.setupLayout(Game.config.jokerLevel);
            }
        } else {
            this.launchesBeforeNewLayout++;
            if (this.launchesBeforeNewLayout < 3) {
                this.createNewDetritus(this.detritusTypes);
            } else {
                this.setupLayout(this.score.CurrentLevel);
                this.launchesBeforeNewLayout = 0;
            }
        }
    }

    private onDetritusMissDustbin(): void {
        this.score.missDustbin();
        //this.view.showMiss();
        if (this.isJokerPhaseRunning) {
            if (this.jokerPhaseDetritus.length == 0) {
                setTimeout(() => {
                    this.endGame();
                }, 600);
            } else {
                this.createNewDetritus(this.detritusTypes);
            }
        } else {
            this.createNewDetritus(this.detritusTypes);
        }
    }

    get stateChanged(): ISimpleEvent<number> {
        return this.onStateChanged.asEvent();
    }

    private onScreenSizeTypeChanged(type: ScreenSizeType) {
        this.dustbinManager.removeDustbins();
        this.setupLayout(this.score.CurrentLevel);
    }
}