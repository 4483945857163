import GameObject from "../logic/GameObject";
import * as THREE from "three";
import GamePhysics from "../logic/GamePhysics";

export default class Floor extends GameObject {

    constructor() {
        super();

        const pos = new THREE.Vector3();
        const quat = new THREE.Quaternion();

        pos.set( 0, 0, 0 );
        quat.setFromAxisAngle( new THREE.Vector3( 1, 0, 0 ), 0 );
        const ground: THREE.Object3D = GamePhysics.createParalellepipedWithPhysics(100, 0.1, 100, 0, pos, quat,0x00D6FF, false, null, this);


        // @ts-ignore
        ground.material.visible = false;
        // @ts-ignore
        ground.material.opacity = 0.2;

        const loader = new THREE.TextureLoader();

        loader.load( "textures/grid.jpg",  ( texture ) => {
            // @ts-ignore
            const material: THREE.MeshBasicMaterial  = ground.material as THREE.MeshBasicMaterial ;
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set( 200, 200 );
            texture.anisotropy = 16;
            material.map = texture;
            material.needsUpdate = true;
            // @ts-ignore
            ground.material = material;
        } );
    }
}