export default class ScoreChangedEventArgs{
    private readonly score: number;
    private readonly scoreAmount: number;
    private readonly hasMiss: boolean

    constructor(score:number, scoreAmount:number, miss:boolean) {
        this.score = score;
        this.scoreAmount = scoreAmount;
        this.hasMiss = miss;
    }

    get Score(): number {
        return this.score;
    }

    get ScoreAmount(): number {
        return this.scoreAmount;
    }

    get HasMiss(): boolean {
        return this.hasMiss;
    }
}