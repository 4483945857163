export default class Rose {

    private rose = <HTMLCanvasElement>document.getElementById("animation-rose");
    private timer:number;

    happy () : void {
        this.rose.classList.add("is-happy");
        this.waitToIdle(500);
    }

    sad() :  void {
        this.rose.classList.add("is-sad");
        this.waitToIdle(500);
    }

    waitToIdle(time:number) : void{
        clearTimeout(this.timer);
        this.timer = <any>setTimeout(() => {
            this.idle();
        }, time);
    }

    idle(): void {
        this.rose.classList.remove("is-happy");
        this.rose.classList.remove("is-sad");
    }
}