import * as THREE from "three";
import Dustbin from "./Dustbin";
import {DetritusType} from "./DetritusType";
import Game from "../Game";
import {ILevel} from "./ILevel";
import {getRandomIntWithManyExclusions} from "Utils";
import {SimpleEventDispatcher} from "strongly-typed-events";
import {ISimpleEvent} from "ste-simple-events/dist/definitions";
import {ScreenSizeType} from "../view/GameView";
import {IDustbinsLayout} from "./IDustbinsLayout";
import {ITexture} from "./ITexture";

export default class DustbinsManager {

    private onLayoutChanged = new SimpleEventDispatcher<Dustbin[]>();

    private dustbins: Dustbin[];
    private mainScene: THREE.Scene;
    private textures: ITexture;

    private dustbinsYHeight =  0.3999999165534973;
    private levelGrid: THREE.Vector3[][];

    private readonly DEBUG = false;

    /**
     * Ctor.
     * @param scene
     * @param textures
     */
    constructor(scene: THREE.Scene, textures: ITexture) {
        this.dustbins = [];
        this.mainScene = scene;
        this.levelGrid = [];
        this.textures = textures;

    }

    update(deltaTime: number, secs: number): void {
        for (let i = 0; i < this.dustbins.length; i++) {
            this.dustbins[i].update(deltaTime, secs);
        }
    }

    setupDustbins(level: ILevel): DetritusType[] {

        if(!this.DEBUG){
            this.removeDustbins();
        }

        const excludeRowsIndex: number[] = [];
        const excludeColsIndex: number[] = [];
        const excludeTypesIndex: number[] =[];
        const rowOccupations: number[][] = [];
        const detritusTypes: DetritusType[] = [];

        for (let i = 0; i < level.dustbinsAmount; i++) {
            const row: number = getRandomIntWithManyExclusions(level.minRowIndex, level.maxRowIndex, excludeRowsIndex);

            // One per row under 2 dustbins
            if (level.dustbinsAmount <= 2) {
                excludeRowsIndex.push(row);
            }

            if (rowOccupations[row] == undefined) {
                rowOccupations[row] = [];
                rowOccupations[row] = rowOccupations[row].concat(excludeColsIndex);
            }

            const col: number = getRandomIntWithManyExclusions(0, this.levelGrid[row].length - 1, rowOccupations[row]);
            rowOccupations[row].push(col);

            // When there are two dustbins, prevents two dustbin at the same col
            if(level.dustbinsAmount == 2){
                excludeColsIndex.push(col);
            }

            // If no slot available in the row. Add this row to the exclude array
            if (rowOccupations[row].length == this.levelGrid[row].length) {
                excludeRowsIndex.push(row);
            }

            const typeIndex = getRandomIntWithManyExclusions(0, level.detritusTypes.length - 1, excludeTypesIndex);
            excludeTypesIndex.push(typeIndex);
            const randomType: DetritusType = level.detritusTypes[typeIndex];

            detritusTypes.push(randomType);
            this.addDustbin(this.mainScene, randomType, new THREE.Vector3(this.levelGrid[row][col].x, this.dustbinsYHeight, this.levelGrid[row][col].z));
        }

        this.onLayoutChanged.dispatch(this.dustbins);
        return detritusTypes;
    }

    removeDustbins(): void {
        for(let i = 0; i < this.dustbins.length;i++){
            this.dustbins[i].destroy();
        }
        this.dustbins = [];
    }

    /**
     * Creates dustbin on the scene
     * @param scene
     * @param type
     * @param position
     */
    addDustbin(scene: THREE.Scene, type: DetritusType, position: THREE.Vector3): void {
        this.dustbins.push(new Dustbin(scene, type, position, this.textures));
    }

    refreshLayoutGrid(type:ScreenSizeType): void {

        const layout: IDustbinsLayout = this.getDustbinLayoutByType(type);
        const dustbinSize = 0.6 + layout.xMargin;

        let row = 0;
        for (let i = layout.closestZ; i >= layout.farestZ; i -= layout.zMargin) {

            //console.log(this.levelGrid[row]);
            this.levelGrid[row] = [];

            // Loi des proportions des triangles rectanlges
            const xPos = i / Math.abs(layout.farestZ) * Math.abs(layout.xMax);

            const width = Math.abs(xPos * 2) + dustbinSize;

            const nb = width / dustbinSize;
            const floored = Math.floor(nb);
            const diff = (nb - floored);

            for (let j = 0; j < floored; j++) {
                const x = (xPos + j * dustbinSize) + (diff * dustbinSize) / 2;
                const z = i;
                this.levelGrid[row][j] = new THREE.Vector3(x, this.dustbinsYHeight, z);
                // TODO: Uncomment this line for debug
                if(this.DEBUG){
                    this.addDustbin(this.mainScene, DetritusType.Glass, new THREE.Vector3(x, this.dustbinsYHeight, z));
                }
            }
            row++;
        }
    }

    get layoutChanged(): ISimpleEvent<Dustbin[]> {
        return this.onLayoutChanged.asEvent();
    }

    getDustbins(): Dustbin[] {
        return this.dustbins;
    }

    getDustbinLayoutByType(type: ScreenSizeType) : IDustbinsLayout {
        let layout:IDustbinsLayout = null;
        for(let i = 0; i < Game.config.dustbinsLayout.length;i++) {
            if(Game.config.dustbinsLayout[i].type === type){
                layout = Game.config.dustbinsLayout[i];
            }
        }
        return layout;
    }
}